import React, { useContext } from 'react';
import { ChatContext } from '../Context/ChatContext';
import MenuItem from '@mui/material/MenuItem';
import NotificationsIcon from '@mui/icons-material/Notifications';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import { useHistory, useLocation } from 'react-router-dom';
import Badge from '@mui/material/Badge';

export default function ChatNotificationModal() {
  const { notificationCount, chatsAsSellerContext, chatsAsBuyerContext, chatsAsDealerSellerContext, setChatsAsDealerSellerContext, setChatsAsSellerContext, setChatsAsBuyerContext, setClaimID, setPlateNumber, setBuyerID, setUserRole, setVIN, setIsMessagePath } = useContext(ChatContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();
  const location = useLocation();

  const handleNotificationClick = (event) => {
    if ((chatsAsSellerContext && chatsAsSellerContext.length > 0) || (chatsAsBuyerContext && chatsAsBuyerContext.length > 0) || (chatsAsDealerSellerContext && chatsAsDealerSellerContext.length > 0)) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (index, chatType, chat, chats) => {
    handleClose();
    const currentPath = location.pathname;
    setIsMessagePath(currentPath === '/message');
    if (chatType === 'buyer') {
      const updatedChats = [...chatsAsBuyerContext];
      updatedChats.splice(index, 1);
      setChatsAsBuyerContext(updatedChats);
      setClaimID(chat.ClaimID);
      setPlateNumber(chat.plateNo);
      setBuyerID(chat.BuyerID);
      setUserRole(chatType);
      setVIN(chat.VIN);
      history.push('/message');
    }
    if (chatType === 'seller') {
      const updatedChats = [...chatsAsSellerContext];
      updatedChats.splice(index, 1);
      setChatsAsSellerContext(updatedChats);
      setClaimID(chat.ClaimID);
      setPlateNumber(chats.plateNo);
      setBuyerID(chat.BuyerID);
      setUserRole(chatType);
      setVIN(chats.VIN);
      history.push('/message');
    }
    if (chatType === 'dealerSeller') {
      const updatedChats = [...chatsAsDealerSellerContext];
      updatedChats.splice(index, 1);
      setChatsAsDealerSellerContext(updatedChats);
      setClaimID(chat.ClaimID);
      setPlateNumber(chats.plateNo);
      setBuyerID(chat.BuyerID);
      setUserRole(chatType);
      setVIN(chats.VIN);
      history.push('/message');
    }
  };

  return (
    <>
      {(
        <div>
          <IconButton onClick={handleNotificationClick}>
            <Badge badgeContent={notificationCount} color="error" style={{ position: 'absolute', bottom: 0, left: '80%', top: '80%', transform: 'translateX(-50%)' }} />
            <NotificationsIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {chatsAsBuyerContext && (chatsAsBuyerContext.length > 0) && chatsAsBuyerContext.map((chat, index) =>
            (
              <div key={index}>
                <MenuItem key={index} onClick={() => handleItemClick(index, 'buyer', chat, null)}>
                  {chat.SellerNickName ? `${chat.SellerNickName}: ${chat.PlateNumber || chat.VIN.substr(-6)}` : `Seller: ${chat.PlateNumber || chat.VIN.substr(-6)}`}
                </MenuItem>
              </div>
            )
            )}
            {chatsAsSellerContext && (chatsAsSellerContext.length > 0) && chatsAsSellerContext.map((chats, index) =>
            (
              <div key={index}>
                {chats.chats.length > 0 && chats.chats.map((chat, index) => (

                  <MenuItem key={index} onClick={() => handleItemClick(index, 'seller', chat, chats)}>
                    {chats.plateNo || chats.VIN.substr(-4)} : {chat.buyerNickName ? `${chat.buyerNickName}${chat.BuyerID.substr(-4)}` : `Buyer: ${chat.BuyerID.substr(-4)}`}
                  </MenuItem>

                ))}
              </div>
            )
            )}
            {chatsAsDealerSellerContext && (chatsAsDealerSellerContext.length > 0) && chatsAsDealerSellerContext.map((chats, index) =>
            (
              <div key={index}>
                {chats.chats.length > 0 && chats.chats.map((chat, index) => (
                  <MenuItem key={index} onClick={() => handleItemClick(index, 'dealerSeller', chat, chats)}>
                    {chats.plateNo || chats.VIN.substr(-4)} : {chat.buyerNickName ? `${chat.buyerNickName}${chat.BuyerID.substr(-4)}` : `Buyer: ${chat.BuyerID.substr(-4)}`}
                  </MenuItem>
                ))}
              </div>
            )
            )}
          </Menu>
        </div>
      )}
    </>
  );
}
