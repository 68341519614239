import React, { useEffect, useState, useContext } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import '../assets/CSS/Profile.css';
import EditIcon from '@material-ui/icons/Edit';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@mui/material/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import { deleteUserPreferences, getUserPreferences } from '../services/PreferenceService';
import { updateNickName } from '../services/AccountService';
import { UserContext } from '../Context/UserContext';
import { jwtToken } from '../Context/DataContext';
import { Spinner } from '../components/Spinner';
import profilePhoto from '../assets/img/profilePhoto.png';
import backgroundCover from '../assets/img/backgroundCover.png';
import swal from 'sweetalert';
import { TextField } from '@material-ui/core';

const Profile = () => {
  const { userID, user, setUser } = useContext(UserContext);
  const { token } = useContext(jwtToken);
  const [loaded, setLoaded] = useState(false);
  const [openModal, setOpenModal] = useState(null);
  const [userPreference, setUserPreference] = useState({});
  const [editedNickName, setEditedNickName] = useState(user.Nickname || '');

  const handleOpen = (modal) => {
    setOpenModal(modal);
  };

  const handleClose = () => {
    setOpenModal(null);
  };

  const handleDelete = async () => {
    try {
      swal({
        text: 'Are you sure you want to delete this preference?',
        icon: 'info',
        buttons: {
          cancel: 'No',
          confirm: {
            text: 'Yes',
            className: 'request-button',
          },
        },
      }).then(async (willDelete) => {
        if (willDelete) {
          await deleteUserPreferences(userID, token);
          console.log('User preference deleted successfully');
          swal({
            text: 'User preference deleted successfully',
            icon: 'success',
            dangerMode: true,
          });
          setUserPreference({});
        }
      });
    } catch (error) {
      swal({
        text: 'Error deleting user preference',
        icon: 'error',
        dangerMode: true,
      });
      console.error('errorDelete', error);
    }
  };

  var nickNameReg = /^[a-zA-Z]{0,10}$/;
  const handleProfile = async () => {
    try {
      if (!editedNickName || !nickNameReg.test(editedNickName)) {
        swal({
          text: 'Nickname should only contain up to 10 alphabetical characters.',
          icon: 'error',
          dangerMode: true,
        });
        console.log('Nickname is invalid');
        return;
      }

      const respNickName = await updateNickName(userID, token, editedNickName);
      setUser(respNickName.data);

      setOpenModal(false);
      console.log('Nickname updated successfully');
      swal({
        text: 'Nickname updated successfully',
        icon: 'success',
        dangerMode: true,
      });
    } catch (error) {
      swal({
        text: 'Failed to update the nickname',
        icon: 'error',
        dangerMode: true,
      });
      console.error('errorEdit', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoaded(true);
      try {
        const res = await getUserPreferences(userID, token);
        setUserPreference(res.data || {});
      } catch (error) {
        if (error.response && error.response.status === 400) {
          swal({
            text: 'Failed to retrieve user preferences',
            icon: 'error',
            dangerMode: true,
          });
        } else {
          console.log(error);
        }
      } finally {
        setLoaded(false);
      }
    };
    fetchData();
  }, [userID, token]);

  return (
    <>
      {loaded ? (
        <div className="middle">
          <Spinner></Spinner>
        </div>
      ) : (
        <>
          <div className="container fullcont" style={{ overflowY: 'scroll' }}>
            {/* Cover Image */}
            <div className="coverimg"
              style={{
                backgroundImage: `url(${backgroundCover})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: -1,
                height: '40vh'
              }}
            />

            {/* Card */}
            <Card className="cardz" style={{ maxWidth: 500, margin: 'auto', position: 'relative', zIndex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: '10px' }}>
              <EditIcon style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }} onClick={() => handleOpen('editProfile')} />
              {/* Modal */}
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal === 'editProfile'}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={openModal}>
                  <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '10px', border: 'none' }}>
                    <h4>Edit Profile</h4>
                    {/* Add your form components here */}
                    <TextField label="NickName" value={editedNickName} onChange={(e) => setEditedNickName(e.target.value)} /><br />
                    <p style={{ color: 'red' }}>{!nickNameReg.test(editedNickName) ? 'Nickname should only contain up to 10 alphabetical characters.' : ''}</p>
                    <Button className="butpro" onClick={handleProfile}>Save Changes</Button>
                    <Button className="butpro" onClick={handleClose}>Close</Button>
                  </div>
                </Fade>
              </Modal>
              <CardMedia
                component="img"
                alt="Circle Image"
                height="20%"
                image={profilePhoto}
                style={{ borderRadius: '50%', width: '20%', marginTop: '10px' }}
              />
              <CardContent style={{ width: '100%', margin: 'auto', position: 'relative', zIndex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography className="profont" variant="h5" component="div">
                  {user.Nickname || ''}
                </Typography>
                {/* <Typography className="profont" variant="body2" color="textSecondary" style={{ textAlign: 'center', margin: '5px' }}>
                  In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate
                </Typography> */}
              </CardContent>

            </Card>
            <Card style={{ marginTop: '10px' }}>
              {userPreference.Make && userPreference.Model && (
                <>
                  <Typography className="profont" variant="h5" component="div" style={{ margin: '10px 20px 5px 20px' }}>
                    Favourites
                  </Typography>
                  <hr style={{ margin: '5px 20px' }} />
                  <div className="col-12 col-md-4 lg-4 sm-4 xs-4">
                    <CardContent>
                      <div className="container prodetails">
                        <div className="row" style={{ textAlign: 'left', margin: '5px' }}>
                          <div className="col-12 md-6 sm-6 xs-6">
                            <Typography className="profont" variant="body1" color="textPrimary" component="p" style={{ margin: '0' }}>• Make</Typography>
                          </div>
                          <div className="col-12 md-6 sm-6 xs-6">
                            <Card style={{ marginLeft: '10px', padding: '5px', marginRight: '10px' }}>
                              <Typography className="profont" color="textPrimary" style={{ textAlign: 'left', margin: '0' }}>{userPreference.Make}</Typography>
                            </Card>
                          </div>
                        </div>
                        <div className="row" style={{ margin: '5px' }}>
                          <div className="col-12 md-6 sm-6 xs-6">
                            <Typography className="profont" variant="body1" color="textPrimary" component="p" style={{ margin: '0' }}>• Model</Typography>
                          </div>
                          <div className="col-12 md-6 sm-6 xs-6">
                            <Card style={{ marginLeft: '10px', padding: '5px', marginRight: '10px' }}>
                              <Typography className="profont" color="textPrimary" style={{ textAlign: 'left', margin: '0' }}>{userPreference.Model}</Typography>
                            </Card>
                          </div>
                        </div>
                        {userPreference.FuelType && (
                          <div className="row" style={{ margin: '5px' }}>
                            <div className="col-12 md-6 sm-6 xs-6">
                              <Typography className="profont" variant="body1" color="textPrimary" component="p" style={{ margin: '0' }}>• Fuel Type</Typography>
                            </div>
                            <div className="col-12 md-6 sm-6 xs-6">
                              <Card style={{ marginLeft: '10px', padding: '5px', marginRight: '10px' }}>
                                <Typography className="profont" color="textPrimary" style={{ textAlign: 'left', margin: '0' }}>{userPreference.FuelType}</Typography>
                              </Card>
                            </div>
                          </div>
                        )}
                        {userPreference.StartYear && (
                          <div className="row" style={{ margin: '5px' }}>
                            <div className="col-12 md-6 sm-6 xs-6">
                              <Typography className="profont" variant="body1" color="textPrimary" component="p" style={{ margin: '0' }}>• Start Year</Typography>
                            </div>
                            <div className="col-12 md-6 sm-6 xs-6">
                              <Card style={{ marginLeft: '10px', padding: '5px', marginRight: '10px' }}>
                                <Typography className="profont" color="textPrimary" style={{ textAlign: 'left', margin: '0' }}>{userPreference.StartYear}</Typography>
                              </Card>
                            </div>
                          </div>
                        )}
                        {userPreference.EndYear && (
                          <div className="row" style={{ margin: '5px' }}>
                            <div className="col-12 md-6 sm-6 xs-6">
                              <Typography className="profont" variant="body1" color="textPrimary" component="p" style={{ margin: '0' }}>• End Year</Typography>
                            </div>
                            <div className="col-12 md-6 sm-6 xs-6">
                              <Card style={{ marginLeft: '10px', padding: '5px', marginRight: '10px' }}>
                                <Typography className="profont" color="textPrimary" style={{ textAlign: 'left', margin: '0' }}>{userPreference.EndYear}</Typography>
                              </Card>
                            </div>
                          </div>
                        )}
                        <br /><br />
                        <DeleteIcon style={{ position: 'absolute', bottom: '10px', right: '50px', cursor: 'pointer' }} onClick={handleDelete} />
                      </div>
                    </CardContent>
                  </div>
                </>
              )}
            </Card>
          </div>
        </>
      )}
    </>
  );
};

export default Profile;