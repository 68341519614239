import React, { useState, useEffect, useContext } from 'react';
import Button from '../claim/button';
import Accordion from 'react-bootstrap/Accordion';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import { Spinner } from '../../components/Spinner';
import { jwtToken } from '../../Context/DataContext';
import { UserContext } from '../../Context/UserContext';
import { VehicleDataContext } from '../../Context/VehicleDataContext';
import { TimestampToDate } from '../../utils/DateAndTimeUtils';
import '../CSS/Steps.css';

export default function Stepthree({ isActive }) {
  const history = useHistory();
  const { token } = useContext(jwtToken);
  const { userID } = useContext(UserContext);
  const { claimObj } = useContext(VehicleDataContext);
  const [selectedPass, setSelectedPass] = useState({});
  const [expiredPasses, setExpiredPasses] = useState([]);
  const [usedPasses, setUsedPasses] = useState([]);
  const [availablePasses, setAvailablePasses] = useState([]);
  const [isTrader, setIsTrader] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [personalPasses, setPersonalPasses] = useState([]);
  const [dealerPasses, setDealerPasses] = useState([]);

  const location = useLocation();
  const claimSkiped = location.state?.claimSkiped || false;
  useEffect(() => {
    if (token && isActive) {
      const fetchPassess = async () => {
        setLoaded(true);
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_COMMON}paymentCheck`,
            { userID }, { headers: { Authorization: `Bearer ${token}` } });

          const { personalPasses, dealerPasses } = response.data;

          if (claimSkiped) {
            // Initialize categorized passes
            const personalCategorized = {
              availablePasses: [],
              usedPasses: [],
              expiredPasses: []
            };

            const dealerCategorized = {
              availablePasses: [],
              usedPasses: [],
              expiredPasses: []
            };

            // Categorize personal passes
            personalPasses.forEach((pass) => {
              if (pass.Status === 'Expired') {
                personalCategorized.expiredPasses.push(pass);
              } else if (pass.Status === 'Active' && pass.AvailablePasses === 0) {
                personalCategorized.usedPasses.push(pass);
              } else if (pass.Status === 'Active' && pass.AvailablePasses > 0) {
                personalCategorized.availablePasses.push(pass);
              }
            });

            // Categorize dealer passes
            dealerPasses.forEach((pass) => {
              if (pass.Status === 'Expired') {
                dealerCategorized.expiredPasses.push(pass);
              } else if (pass.Status === 'Active' && pass.AvailablePasses === 0) {
                dealerCategorized.usedPasses.push(pass);
              } else if (pass.Status === 'Active' && pass.AvailablePasses > 0) {
                dealerCategorized.availablePasses.push(pass);
              }
            });

            // Update states
            setPersonalPasses(personalCategorized);
            setDealerPasses(dealerCategorized);

            // Initially set passes based on trader status
          } else {
            let passes;
            if (claimObj.DealerID) {
              passes = dealerPasses;
            } else {
              passes = personalPasses;
            }

            if (passes.length > 0) {
              const expiredPasses = [];
              const usedPasses = [];
              const availablePasses = [];
              passes.forEach((payment) => {
                if (payment.Status === 'Expired') {
                  expiredPasses.push(payment);
                } else if (payment.Status === 'Active' && payment.AvailablePasses === 0) {
                  usedPasses.push(payment);
                } else if (payment.Status === 'Active' && payment.AvailablePasses > 0) {
                  availablePasses.push(payment);
                }
              });
              setExpiredPasses(expiredPasses);
              setUsedPasses(usedPasses);
              setAvailablePasses(availablePasses);
            }
          }
          setLoaded(false);
        } catch (error) {
          setLoaded(false);
          console.log(error.message);
        }
      };
      fetchPassess();
    }
  }, [isActive, token, userID, claimObj]);

  // Handle switching between personal and dealer passes
  const handleAutoTraderChange = (e) => {
    setIsTrader(e.target.checked);
    setSelectedPass({});
  };

  const handleClickpath = () => {
    history.replace('/claim/Not%20Listed?activeStep=4', { selectedPass, claimSkiped, isTrader });
  };
  const previouspagehandler = () => {
    if (claimSkiped) {
      history.replace('/claim/Not%20Listed?activeStep=1');
    } else {
      history.replace('/claim/Not%20Listed?activeStep=2');
    }
  };

  if (claimSkiped) {
    return (
      <div className="container">
        <div className="three">
          {loaded ? (<Spinner></Spinner>) : (
            <>
              <Button className="right-btn" onClick={() => history.replace('/details')}>
                List Later
              </Button>
              <h3 className="claim-topic-color">Select a Pass!</h3>
              &nbsp;
              <div className="form-group">
                <Form.Check
                  type='checkbox'
                  id={`trader-checkbox`}
                  label={`Are you an Auto Trader?`}
                  checked={isTrader}
                  onChange={handleAutoTraderChange}
                />
              </div>
              <br />
              <div className='pt-4'>
                <Accordion defaultActiveKey={['0']} >
                  {personalPasses?.availablePasses?.length > 0 && (
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Personal Available Passes</Accordion.Header>
                      <Accordion.Body>
                        <table>
                          <thead>
                            <tr>
                              <th></th>
                              <th>Name</th>
                              <th>Available Passes</th>
                              <th>Used Passes</th>
                              <th>Expiry Date</th>
                              <th>Claim Validity</th>
                            </tr>
                          </thead>
                          <tbody>
                            {personalPasses.availablePasses.map((availablePass, index) => (
                              <tr key={index}>
                                <td>
                                  <input
                                    type="radio"
                                    value={availablePass}
                                    checked={selectedPass.CreatedAt === availablePass.CreatedAt}
                                    onChange={() => setSelectedPass(availablePass)}
                                    disabled={isTrader}
                                  />
                                </td>
                                <td>{availablePass.PricingName}</td>
                                <td>{availablePass.AvailablePasses}</td>
                                <td>{availablePass.UsedPasses}</td>
                                <td>{TimestampToDate(availablePass.ExpiresAt)}</td>
                                <td>{availablePass.ClaimValidity || 90} days</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                  {personalPasses?.usedPasses?.length > 0 && (
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Personal Used Passes</Accordion.Header>
                      <Accordion.Body>
                        <table>
                          <thead>
                            <tr>
                              <th></th>
                              <th>Name</th>
                              <th>Available Passes</th>
                              <th>Used Passes</th>
                              <th>Expiry Date</th>
                              <th>Claim Validity</th>
                            </tr>
                          </thead>
                          <tbody>
                            {personalPasses?.usedPasses.map((usedPass, index) => (
                              <tr key={index}>
                                <td><input type="radio" disabled={true} /></td>
                                <td>{usedPass.PricingName}</td>
                                <td>{usedPass.AvailablePasses}</td>
                                <td>{usedPass.UsedPasses}</td>
                                <td>{TimestampToDate(usedPass.ExpiresAt)}</td>
                                <td>{usedPass.ClaimValidity || 90} days</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                  {personalPasses?.expiredPasses?.length > 0 && (
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Personal Expired Passes</Accordion.Header>
                      <Accordion.Body>
                        <table>
                          <thead>
                            <tr>
                              <th></th>
                              <th>Name</th>
                              <th>Available Passes</th>
                              <th>Used Passes</th>
                              <th>Expired Date</th>
                              <th>Claim Validity</th>
                            </tr>
                          </thead>
                          <tbody>
                            {personalPasses?.expiredPasses.map((expiredPass, index) => (
                              <tr key={index}>
                                <td><input type="radio" disabled={true} /></td>
                                <td>{expiredPass.PricingName}</td>
                                <td>{expiredPass.AvailablePasses}</td>
                                <td>{expiredPass.UsedPasses}</td>
                                <td>{TimestampToDate(expiredPass.ExpiresAt)}</td>
                                <td>{expiredPass.ClaimValidity || 90} days</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                </Accordion>
                <Accordion defaultActiveKey={['0']}>
                  {dealerPasses?.availablePasses?.length > 0 && (
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Dealer Available Passes</Accordion.Header>
                      <Accordion.Body>
                        <table>
                          <thead>
                            <tr>
                              <th></th>
                              <th>Name</th>
                              <th>Available Passes</th>
                              <th>Used Passes</th>
                              <th>Expiry Date</th>
                              <th>Claim Validity</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dealerPasses.availablePasses.map((availablePass, index) => (
                              <tr key={index}>
                                <td>
                                  <input
                                    type="radio"
                                    value={availablePass}
                                    checked={selectedPass.CreatedAt === availablePass.CreatedAt}
                                    onChange={() => setSelectedPass(availablePass)}
                                    disabled={!isTrader}
                                  />
                                </td>
                                <td>{availablePass.PricingName}</td>
                                <td>{availablePass.AvailablePasses}</td>
                                <td>{availablePass.UsedPasses}</td>
                                <td>{TimestampToDate(availablePass.ExpiresAt)}</td>
                                <td>{availablePass.ClaimValidity || 90} days</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                  {dealerPasses?.usedPasses?.length > 0 && (
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Dealer Used Passes</Accordion.Header>
                      <Accordion.Body>
                        <table>
                          <thead>
                            <tr>
                              <th></th>
                              <th>Name</th>
                              <th>Available Passes</th>
                              <th>Used Passes</th>
                              <th>Expiry Date</th>
                              <th>Claim Validity</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dealerPasses.usedPasses.map((usedPass, index) => (
                              <tr key={index}>
                                <td><input type="radio" disabled={true} /></td>
                                <td>{usedPass.PricingName}</td>
                                <td>{usedPass.AvailablePasses}</td>
                                <td>{usedPass.UsedPasses}</td>
                                <td>{TimestampToDate(usedPass.ExpiresAt)}</td>
                                <td>{usedPass.ClaimValidity || 90} days</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                  {dealerPasses?.expiredPasses?.length > 0 && (
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Dealer Expired Passes</Accordion.Header>
                      <Accordion.Body>
                        <table>
                          <thead>
                            <tr>
                              <th></th>
                              <th>Name</th>
                              <th>Available Passes</th>
                              <th>Used Passes</th>
                              <th>Expired Date</th>
                              <th>Claim Validity</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dealerPasses.expiredPasses.map((expiredPass, index) => (
                              <tr key={index}>
                                <td><input type="radio" disabled={true} /></td>
                                <td>{expiredPass.PricingName}</td>
                                <td>{expiredPass.AvailablePasses}</td>
                                <td>{expiredPass.UsedPasses}</td>
                                <td>{TimestampToDate(expiredPass.ExpiresAt)}</td>
                                <td>{expiredPass.ClaimValidity || 90} days</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                </Accordion>
              </div>
              <br />
              {(dealerPasses?.availablePasses?.length === 0 || personalPasses?.availablePasses?.length === 0) && <div>
                <h6>You need one or more vehicle passes to complete the listing, please purchase vehicle passes here.</h6>
                <Link to="/account">Account</Link>
              </div>}
              <div className="justify-content-center" style={{ display: 'flex' }}>
                <Button className="red btn my-1" onClick={previouspagehandler}>
                  Previous
                </Button>
                {(dealerPasses?.availablePasses?.length > 0 || personalPasses?.availablePasses?.length > 0) && (
                  <Button
                    className="red btn my-1"
                    onClick={handleClickpath}
                    disabled={Object.keys(selectedPass).length === 0 ? true : false}
                  >
                    Next
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  // Return when claimSkiped is false
  return (
    <div className="container">
      <div className="three">
        {loaded ? (<Spinner />) : (
          <>
            <Button className="right-btn" onClick={() => history.replace('/details')}>
              List Later
            </Button>
            <h3 className="claim-topic-color">Select a Pass!</h3>
            <div className='pt-4'>
              <Accordion defaultActiveKey={['0']} alwaysOpen>
                {availablePasses.length > 0 && (
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Available Passes</Accordion.Header>
                    <Accordion.Body>
                      <table>
                        <thead>
                          <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Available Passes</th>
                            <th>Used Passes</th>
                            <th>Expiry Date</th>
                            <th>Claim Validity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {availablePasses.map((availablePass, index) => (
                            <tr key={index}>
                              <td>
                                <input
                                  type="radio"
                                  value={availablePass}
                                  checked={selectedPass.CreatedAt === availablePass.CreatedAt}
                                  onChange={() => setSelectedPass(availablePass)}
                                />
                              </td>
                              <td>{availablePass.PricingName}</td>
                              <td>{availablePass.AvailablePasses}</td>
                              <td>{availablePass.UsedPasses}</td>
                              <td>{TimestampToDate(availablePass.ExpiresAt)}</td>
                              <td>{availablePass.ClaimValidity || 90} days</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {usedPasses.length > 0 && (
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Used Passes</Accordion.Header>
                    <Accordion.Body>
                      <table>
                        <thead>
                          <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Available Passes</th>
                            <th>Used Passes</th>
                            <th>Expiry Date</th>
                            <th>Claim Validity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {usedPasses.map((usedPass, index) => (
                            <tr key={index}>
                              <td><input type="radio" disabled={true} /></td>
                              <td>{usedPass.PricingName}</td>
                              <td>{usedPass.AvailablePasses}</td>
                              <td>{usedPass.UsedPasses}</td>
                              <td>{TimestampToDate(usedPass.ExpiresAt)}</td>
                              <td>{usedPass.ClaimValidity || 90} days</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {expiredPasses.length > 0 && (
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Expired Passes</Accordion.Header>
                    <Accordion.Body>
                      <table>
                        <thead>
                          <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Available Passes</th>
                            <th>Used Passes</th>
                            <th>Expired Date</th>
                            <th>Claim Validity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {expiredPasses.map((expiredPass, index) => (
                            <tr key={index}>
                              <td><input type="radio" disabled={true} /></td>
                              <td>{expiredPass.PricingName}</td>
                              <td>{expiredPass.AvailablePasses}</td>
                              <td>{expiredPass.UsedPasses}</td>
                              <td>{TimestampToDate(expiredPass.ExpiresAt)}</td>
                              <td>{expiredPass.ClaimValidity || 90} days</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
              </Accordion>
            </div>
            <br />
            {availablePasses.length === 0 && (
              <div>
                <h6>You need one or more vehicle passes to complete the listing, please purchase vehicle passes here.</h6>
                <Link to="/account">Account</Link>
              </div>
            )}
            <div className="justify-content-center" style={{ display: 'flex' }}>
              <Button className="red btn my-1" onClick={previouspagehandler}>
                Previous
              </Button>
              {availablePasses.length > 0 && (
                <Button
                  className="red btn my-1"
                  onClick={handleClickpath}
                  disabled={Object.keys(selectedPass).length === 0}
                >
                  Next
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
