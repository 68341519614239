import axios from 'axios';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../assets/CSS/Offers.css';
import { jwtToken } from '../Context/DataContext';
import { ButtonSpinner, Spinner } from './Spinner';
import { UserContext } from '../Context/UserContext';
import { TimestampToDate } from '../utils/DateAndTimeUtils';
import swal from 'sweetalert';
import Accordion from 'react-bootstrap/Accordion';
import '../assets/CSS/LandingPage.css';
import '../assets/CSS/ReceivedOffers.css';
import { ChatContext } from '../Context/ChatContext';

export default function Offers() {
  const { token } = useContext(jwtToken);
  const { userID } = useContext(UserContext);
  const { setClaimID, setPlateNumber, setBuyerID, setUserRole, setVIN } = useContext(ChatContext);
  const [searchData, setSearchData] = useState('');
  const [searchOffers, setSearchOffers] = useState('');
  const [initialLoading, setInitialLoading] = useState(false);
  const [acceptLoading, setAcceptLoading] = useState('');
  const [rejectLoading, setRejectLoading] = useState('');
  const [activePersonalOffers, setActivePersonalOffers] = useState([]);
  const [inactivePersonalOffers, setInactivePersonalOffers] = useState([]);
  const [expiredPersonalOffers, setExpiredPersonalOffers] = useState([]);
  const history = useHistory();

  const [activeDealerOffers, setActiveDealerOffers] = useState([]);
  const [inactiveDealerOffers, setInactiveDealerOffers] = useState([]);
  const [expiredDealerOffers, setExpiredDealerOffers] = useState([]);

  const fetchRecievedOffers = useCallback(async () => {
    if (token && userID) {
      try {
        setInitialLoading(true);
        const response = await axios.post(`${process.env.REACT_APP_API_COMMON}recievedoffers`,
          { userID }, { headers: { Authorization: `Bearer ${token}` } }
        );
        const { IndividualReceivedOffers, DealerReceivedOffers } = response.data;

        setActivePersonalOffers(IndividualReceivedOffers.filter((offer) => offer.ClaimStatus === 'Active' || offer.ClaimStatus === 'Pending'));
        setInactivePersonalOffers(IndividualReceivedOffers.filter((offer) => offer.ClaimStatus === 'Inactive'));
        setExpiredPersonalOffers(IndividualReceivedOffers.filter((offer) => offer.ClaimStatus === 'Expired'));

        setActiveDealerOffers(DealerReceivedOffers.filter((offer) => offer.ClaimStatus === 'Active' || offer.ClaimStatus === 'Pending'));
        setInactiveDealerOffers(DealerReceivedOffers.filter((offer) => offer.ClaimStatus === 'Inactive'));
        setExpiredDealerOffers(DealerReceivedOffers.filter((offer) => offer.ClaimStatus === 'Expired'));

      } catch (error) {
        swal({
          text: 'Failed to retrieve received offers!',
          icon: 'error',
          dangerMode: true,
        });
      } finally {
        setInitialLoading(false);
      }
    }
  }, [token, userID]);

  useEffect(() => {
    fetchRecievedOffers();
  }, [fetchRecievedOffers]);

  const acceptConfirm = async (offer) => {
    const { Amount, OfferID: offerID, PlateNumber, VIN } = offer;
    swal({
      title: `Accept the offer amount $${Amount}!`,
      text: `You can Accept only one offer for ${PlateNumber || VIN} vehicle!`,
      icon: "info",
      buttons: {
        cancel: "Cancel",
        defeat: {
          text: 'Accept',
          className: 'request-button' // Add a custom class name for the button
        }
      },
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          setAcceptLoading(offerID);
          try {
            await axios.post(`${process.env.REACT_APP_API_COMMON}acceptoffer`, { userID, offerID },
              { headers: { Authorization: `Bearer ${token}` } });
            await fetchRecievedOffers();
            swal({
              text: 'Offer accepted successfully!',
              icon: 'success',
              dangerMode: true,
            });
          } catch (error) {
            swal({
              text: 'Failed to accept the offer!',
              icon: 'error',
              dangerMode: true,
            });
          } finally {
            setAcceptLoading('');
          }
        }
      });
  };

  const isPassesEmpty = [
    activePersonalOffers,
    inactivePersonalOffers,
    expiredPersonalOffers,
    // pendingPersonalOffers,
    activeDealerOffers,
    inactiveDealerOffers,
    expiredDealerOffers,
    // pendingDealerOffers,
  ].every(arr => arr.length === 0);

  const rejectConfirm = async (offer) => {
    const { Amount, OfferID: offerID, PlateNumber, VIN } = offer;
    swal({
      title: `Reject Offer!`,
      text: `Are you sure that you want to reject the amount $${Amount} for ${PlateNumber || VIN}?`,
      icon: "info",
      buttons: {
        cancel: "Cancel",
        defeat: {
          text: 'Reject',
          className: 'request-button' // Add a custom class name for the button
        }
      },
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          setRejectLoading(offerID);
          try {
            await axios.post(`${process.env.REACT_APP_API_COMMON}rejectoffer`, { userID, offerID },
              { headers: { Authorization: `Bearer ${token}` } });
            await fetchRecievedOffers();
            swal({
              text: 'Offer rejected successfully!',
              icon: 'success',
              dangerMode: true,
            });
          } catch (error) {
            swal({
              text: 'Failed to reject the offer!',
              icon: 'error',
              dangerMode: true,
            });
          } finally {
            setRejectLoading('');
          }
        }
      });
  };

  function handleChat(offer, role) {
    setClaimID(offer.ClaimID);
    setPlateNumber(offer.PlateNumber);
    setBuyerID(offer.BuyerID);
    setUserRole(role);
    setVIN(offer.VIN);
    history.push('/message');
  }

  return (
    <>
      {initialLoading && !acceptLoading && !rejectLoading ? (<Spinner></Spinner>) : (
        <div className="App p-spacing">
          {/* Form Section*/}
          <div className="container  m-spacing">
            {isPassesEmpty ? (<div className="text-center my-5 py-5">No Offers</div>) : (
              <>
                <div className="offer-details">
                  <div className="form-group mb-2">
                    <div className="row mb-5">
                      <div className="col mb-2">
                        <input
                          type="text"
                          placeholder="Search from plate number/VIN"
                          className="form-control"
                          onChange={(e) => {
                            setSearchData(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col mb-2">
                        <input
                          type="text"
                          placeholder="Search offers greater than"
                          className="form-control"
                          onChange={(e) => setSearchOffers(e.target.value)}
                        />
                      </div>
                      <div className="col-3">
                        <button className="btn btn-danger" id="search-button" type="button" style={{ height: '90%' }}>
                          {' '}
                          Search &nbsp;
                          <i className="fa fa-search"></i>
                        </button>
                      </div>
                    </div>
                    <div>
                      <Accordion defaultActiveKey={['0']} alwaysOpen>
                        {activePersonalOffers.length > 0 && (
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>Active Personal Offers</Accordion.Header>
                            <Accordion.Body>
                              <div className="table-container">
                                <table className="table align-middle mb-0 mt-2 bg-white">
                                  <thead className="bg-light">
                                    <tr>
                                      <th style={{ width: '15%' }}>Plate Number/VIN</th>
                                      <th style={{ width: '15%' }}>Created Date</th>
                                      <th style={{ width: '15%' }}>Expire Date</th>
                                      <th style={{ width: '15%' }}>Offered Amount</th>
                                      <th style={{ width: '15%' }}>Status</th>
                                      <th style={{ width: '25%' }}>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {activePersonalOffers
                                      .filter((val) => {
                                        if (!searchData.trim()) {
                                          return val;
                                        } else if (val.PlateNumber?.toLowerCase().includes(searchData.toLocaleLowerCase()) || val.VIN?.toLowerCase().includes(searchData.toLocaleLowerCase())) {
                                          return val;
                                        }
                                        return null;
                                      })
                                      .filter((vall) => {
                                        if (searchOffers === '') {
                                          return vall;
                                        } else if (vall.Amount > parseInt(searchOffers)) {
                                          return vall;
                                        }
                                        return null;
                                      })
                                      .sort((a, b) => new Date(b.Date) - new Date(a.Date))
                                      .map((offer, index) => (
                                        <tr key={index}>
                                          <td>
                                            <p className="text-muted mb-0">{offer.PlateNumber || offer.VIN}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">{TimestampToDate(offer.CreatedAt)}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">{TimestampToDate(offer.ExpireAt)}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">${offer.Amount}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">{offer.Status}</p>
                                          </td>
                                          <td className="">
                                            <div className="flex">
                                              <Button
                                                className='me-3'
                                                id="accept-btn"
                                                variant="success"
                                                disabled={offer.Status !== 'New' || offer.hasAcceptedOfferClaim || acceptLoading || rejectLoading ? true : false}
                                                onClick={() => acceptConfirm(offer)}
                                              >
                                                {acceptLoading === offer.OfferID ? (
                                                  <ButtonSpinner />
                                                ) : (
                                                  'Accept'
                                                )}
                                              </Button>
                                              <Button
                                                className='me-3'
                                                id="reject-btn"
                                                variant="danger"
                                                disabled={offer.Status !== 'New' || offer.hasAcceptedOfferClaim || rejectLoading || acceptLoading ? true : false}
                                                onClick={() => rejectConfirm(offer)}
                                              >
                                                {rejectLoading === offer.OfferID ? (
                                                  <ButtonSpinner />
                                                ) : (
                                                  'Reject'
                                                )}
                                              </Button>
                                              <Button className='me-3' id='Message-btn' variant="success" onClick={() => handleChat(offer, 'seller')}>Message</Button>
                                            </div>
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        )}
                        {expiredPersonalOffers.length > 0 && (
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>Expired Personal Offers</Accordion.Header>
                            <Accordion.Body>

                              <div className="table-container">
                                <table className="table align-middle mb-0 mt-2 bg-white">
                                  <thead className="bg-light">
                                    <tr>
                                      <th style={{ width: '15%' }}>Plate Number/VIN</th>
                                      <th style={{ width: '15%' }}>Created Date</th>
                                      <th style={{ width: '15%' }}>Expire Date</th>
                                      <th style={{ width: '15%' }}>Offered Amount</th>
                                      <th style={{ width: '15%' }}>Status</th>
                                      <th style={{ width: '25%' }}>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {expiredPersonalOffers
                                      .filter((val) => {
                                        if (!searchData.trim()) {
                                          return val;
                                        } else if (val.PlateNumber?.toLowerCase().includes(searchData.toLocaleLowerCase()) || val.VIN?.toLowerCase().includes(searchData.toLocaleLowerCase())) {
                                          return val;
                                        }
                                        return null;
                                      })
                                      .filter((vall) => {
                                        if (searchOffers === '') {
                                          return vall;
                                        } else if (vall.Amount > parseInt(searchOffers)) {
                                          return vall;
                                        }
                                        return null;
                                      })
                                      .sort((a, b) => new Date(b.Date) - new Date(a.Date))
                                      .map((offer, index) => (
                                        <tr key={index}>
                                          <td >
                                            <p className="text-muted mb-0">{offer.PlateNumber}</p>
                                          </td>
                                          <td >
                                            <p className="text-muted mb-0">{TimestampToDate(offer.CreatedAt)}</p>
                                          </td>
                                          <td >
                                            <p className="text-muted mb-0">{TimestampToDate(offer.ExpireAt)}</p>
                                          </td>
                                          <td >
                                            <p className="text-muted mb-0">${offer.Amount}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">{offer.Status === 'Pending' ? 'New' : offer.Status}</p>
                                          </td>
                                          <td className="">
                                            <div className="flex">
                                              <Button
                                                className='me-3'
                                                id="reject-btn"
                                                variant="danger"
                                                disabled={offer.Status !== 'New' || offer.hasAcceptedOfferClaim || rejectLoading || acceptLoading ? true : false}
                                                onClick={() => rejectConfirm(offer)}
                                              >
                                                {rejectLoading === offer.OfferID ? (
                                                  <ButtonSpinner />
                                                ) : (
                                                  'Reject'
                                                )}
                                              </Button>
                                            </div>
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        )}
                        {inactivePersonalOffers.length > 0 && (
                          <Accordion.Item eventKey="2">
                            <Accordion.Header>Inactive Personal Offers</Accordion.Header>
                            <Accordion.Body>
                              <div className="table-container">
                                <table className="table align-middle mb-0 mt-2 bg-white">
                                  <thead className="bg-light">
                                    <tr>
                                      <th style={{ width: '15%' }}>Plate Number/VIN</th>
                                      <th style={{ width: '15%' }}>Created Date</th>
                                      <th style={{ width: '15%' }}>Expire Date</th>
                                      <th style={{ width: '15%' }}>Offered Amount</th>
                                      <th style={{ width: '15%' }}>Status</th>
                                      <th style={{ width: '25%' }}>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {inactivePersonalOffers
                                      .filter((val) => {
                                        if (!searchData.trim()) {
                                          return val;
                                        } else if (val.PlateNumber?.toLowerCase().includes(searchData.toLocaleLowerCase()) || val.VIN?.toLowerCase().includes(searchData.toLocaleLowerCase())) {
                                          return val;
                                        }
                                        return null;
                                      })
                                      .filter((vall) => {
                                        if (searchOffers === '') {
                                          return vall;
                                        } else if (vall.Amount > parseInt(searchOffers)) {
                                          return vall;
                                        }
                                        return null;
                                      })
                                      .sort((a, b) => new Date(b.Date) - new Date(a.Date))
                                      .map((offer, index) => (
                                        <tr key={index}>
                                          <td >
                                            <p className="text-muted mb-0">{offer.PlateNumber || offer.VIN}</p>
                                          </td>
                                          <td >
                                            <p className="text-muted mb-0">{TimestampToDate(offer.CreatedAt)}</p>
                                          </td>
                                          <td >
                                            <p className="text-muted mb-0">{TimestampToDate(offer.ExpireAt)}</p>
                                          </td>
                                          <td >
                                            <p className="text-muted mb-0">${offer.Amount}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">{offer.Status === 'Pending' ? 'New' : offer.Status}</p>
                                          </td>
                                          <td className="">
                                            <div className="flex">
                                              <Button
                                                className='me-3'
                                                id="reject-btn"
                                                variant="danger"
                                                disabled={offer.Status !== 'New' || offer.hasAcceptedOfferClaim || rejectLoading || acceptLoading ? true : false}
                                                onClick={() => rejectConfirm(offer)}
                                              >
                                                {rejectLoading === offer.OfferID ? (
                                                  <ButtonSpinner />
                                                ) : (
                                                  'Reject'
                                                )}
                                              </Button>
                                            </div>
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        )}
                      </Accordion>
                    </div>

                    <br />
                    <div>
                      <Accordion defaultActiveKey={['0']} alwaysOpen>
                        {activeDealerOffers.length > 0 && (
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>Active Dealer Offers</Accordion.Header>
                            <Accordion.Body>
                              <div className="table-container">
                                <table className="table align-middle mb-0 mt-2 bg-white">
                                  <thead className="bg-light">
                                    <tr>
                                      <th style={{ width: '15%' }}>Plate Number/VIN</th>
                                      <th style={{ width: '15%' }}>Created Date</th>
                                      <th style={{ width: '15%' }}>Expire Date</th>
                                      <th style={{ width: '15%' }}>Offered Amount</th>
                                      <th style={{ width: '15%' }}>Status</th>
                                      <th style={{ width: '25%' }}>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {activeDealerOffers
                                      .filter((val) => {
                                        if (!searchData.trim()) {
                                          return val;
                                        } else if (val.PlateNumber?.toLowerCase().includes(searchData.toLocaleLowerCase()) || val.VIN?.toLowerCase().includes(searchData.toLocaleLowerCase())) {
                                          return val;
                                        }
                                        return null;
                                      })
                                      .filter((vall) => {
                                        if (searchOffers === '') {
                                          return vall;
                                        } else if (vall.Amount > parseInt(searchOffers)) {
                                          return vall;
                                        }
                                        return null;
                                      })
                                      .sort((a, b) => new Date(b.Date) - new Date(a.Date))
                                      .map((offer, index) => (
                                        <tr key={index}>
                                          <td>
                                            <p className="text-muted mb-0">{offer.PlateNumber || offer.VIN}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">{TimestampToDate(offer.CreatedAt)}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">{TimestampToDate(offer.ExpireAt)}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">${offer.Amount}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">{offer.Status}</p>
                                          </td>
                                          <td className="">
                                            <div className="flex">
                                              <Button
                                                className='me-3'
                                                id="accept-btn"
                                                variant="success"
                                                disabled={offer.Status !== 'New' || offer.hasAcceptedOfferClaim || acceptLoading || rejectLoading ? true : false}
                                                onClick={() => acceptConfirm(offer)}
                                              >
                                                {acceptLoading === offer.OfferID ? (
                                                  <ButtonSpinner />
                                                ) : (
                                                  'Accept'
                                                )}
                                              </Button>
                                              <Button
                                                className='me-3'
                                                id="reject-btn"
                                                variant="danger"
                                                disabled={offer.Status !== 'New' || offer.hasAcceptedOfferClaim || rejectLoading || acceptLoading ? true : false}
                                                onClick={() => rejectConfirm(offer)}
                                              >
                                                {rejectLoading === offer.OfferID ? (
                                                  <ButtonSpinner />
                                                ) : (
                                                  'Reject'
                                                )}
                                              </Button>
                                              <Button className='me-3' id='Message-btn' variant="success" onClick={() => handleChat(offer, 'dealerSeller')}>Message</Button>
                                            </div>
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        )}
                        {expiredDealerOffers.length > 0 && (
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>Expired Dealer Offers</Accordion.Header>
                            <Accordion.Body>
                              <div className="table-container">
                                <table className="table align-middle mb-0 mt-2 bg-white">
                                  <thead className="bg-light">
                                    <tr>
                                      <th style={{ width: '15%' }}>Plate Number/VIN</th>
                                      <th style={{ width: '15%' }}>Created Date</th>
                                      <th style={{ width: '15%' }}>Expire Date</th>
                                      <th style={{ width: '15%' }}>Offered Amount</th>
                                      <th style={{ width: '15%' }}>Status</th>
                                      <th style={{ width: '25%' }}>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {expiredDealerOffers
                                      .filter((val) => {
                                        if (!searchData.trim()) {
                                          return val;
                                        } else if (val.PlateNumber?.toLowerCase().includes(searchData.toLocaleLowerCase()) || val.VIN?.toLowerCase().includes(searchData.toLocaleLowerCase())) {
                                          return val;
                                        }
                                        return null;
                                      })
                                      .filter((vall) => {
                                        if (searchOffers === '') {
                                          return vall;
                                        } else if (vall.Amount > parseInt(searchOffers)) {
                                          return vall;
                                        }
                                        return null;
                                      })
                                      .sort((a, b) => new Date(b.Date) - new Date(a.Date))
                                      .map((offer, index) => (
                                        <tr key={index}>
                                          <td >
                                            <p className="text-muted mb-0">{offer.PlateNumber}</p>
                                          </td>
                                          <td >
                                            <p className="text-muted mb-0">{TimestampToDate(offer.CreatedAt)}</p>
                                          </td>
                                          <td >
                                            <p className="text-muted mb-0">{TimestampToDate(offer.ExpireAt)}</p>
                                          </td>
                                          <td >
                                            <p className="text-muted mb-0">${offer.Amount}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">{offer.Status === 'Pending' ? 'New' : offer.Status}</p>
                                          </td>
                                          <td className="">
                                            <div className="flex">
                                              <Button
                                                className='me-3'
                                                id="reject-btn"
                                                variant="danger"
                                                disabled={offer.Status !== 'New' || offer.hasAcceptedOfferClaim || rejectLoading || acceptLoading ? true : false}
                                                onClick={() => rejectConfirm(offer)}
                                              >
                                                {rejectLoading === offer.OfferID ? (
                                                  <ButtonSpinner />
                                                ) : (
                                                  'Reject'
                                                )}
                                              </Button>
                                            </div>
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        )}
                        {inactiveDealerOffers.length > 0 && (
                          <Accordion.Item eventKey="2">
                            <Accordion.Header>Inactive Dealer Offers</Accordion.Header>
                            <Accordion.Body>
                              <div className="table-container">
                                <table className="table align-middle mb-0 mt-2 bg-white">
                                  <thead className="bg-light">
                                    <tr>
                                      <th style={{ width: '15%' }}>Plate Number/VIN</th>
                                      <th style={{ width: '15%' }}>Created Date</th>
                                      <th style={{ width: '15%' }}>Expire Date</th>
                                      <th style={{ width: '15%' }}>Offered Amount</th>
                                      <th style={{ width: '15%' }}>Status</th>
                                      <th style={{ width: '25%' }}>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {inactiveDealerOffers
                                      .filter((val) => {
                                        if (!searchData.trim()) {
                                          return val;
                                        } else if (val.PlateNumber?.toLowerCase().includes(searchData.toLocaleLowerCase()) || val.VIN?.toLowerCase().includes(searchData.toLocaleLowerCase())) {
                                          return val;
                                        }
                                        return null;
                                      })
                                      .filter((vall) => {
                                        if (searchOffers === '') {
                                          return vall;
                                        } else if (vall.Amount > parseInt(searchOffers)) {
                                          return vall;
                                        }
                                        return null;
                                      })
                                      .sort((a, b) => new Date(b.Date) - new Date(a.Date))
                                      .map((offer, index) => (
                                        <tr key={index}>
                                          <td >
                                            <p className="text-muted mb-0">{offer.PlateNumber || offer.VIN}</p>
                                          </td>
                                          <td >
                                            <p className="text-muted mb-0">{TimestampToDate(offer.CreatedAt)}</p>
                                          </td>
                                          <td >
                                            <p className="text-muted mb-0">{TimestampToDate(offer.ExpireAt)}</p>
                                          </td>
                                          <td >
                                            <p className="text-muted mb-0">${offer.Amount}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">{offer.Status === 'Pending' ? 'New' : offer.Status}</p>
                                          </td>
                                          <td className="">
                                            <div className="flex">
                                              <Button
                                                className='me-3'
                                                id="reject-btn"
                                                variant="danger"
                                                disabled={offer.Status !== 'New' || offer.hasAcceptedOfferClaim || rejectLoading || acceptLoading ? true : false}
                                                onClick={() => rejectConfirm(offer)}
                                              >
                                                {rejectLoading === offer.OfferID ? (
                                                  <ButtonSpinner />
                                                ) : (
                                                  'Reject'
                                                )}
                                              </Button>
                                            </div>
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        )}
                      </Accordion>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div >
      )
      }
    </>
  );
}
