import React from 'react';
import '../../assets/CSS/DetailField.css';

export default function VehicleSummary({ vehicleDetails }) {
  return (
    <>
      <div className="text-cell">
        <div className="part1">Plate Number</div>
        <div className="part2">{vehicleDetails?.PlateNumber || 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Plate Status</div>
        <div className="part2">{vehicleDetails?.Status || 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Plate Type</div>
        <div className="part2">{vehicleDetails?.PlateType || 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Model</div>
        <div className="part2">{vehicleDetails?.Model || 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Make</div>
        <div className="part2">{vehicleDetails?.Make || 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Year</div>
        <div className="part2">{vehicleDetails?.YearOfManufacture || 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">Body Style</div>
        <div className="part2">{vehicleDetails?.BodyStyle || 'N/A'}</div>
      </div>
      <div className="text-cell">
        <div className="part1">VIN</div>
        <div className="part2 vin">{vehicleDetails?.VIN || 'N/A'}</div>
      </div>
    </>
  );
}
